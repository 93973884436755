<template>
  <v-app
    id="inspire"
    :style="{ background: $vuetify.theme.themes.light.background }"
  >
    <v-main>
      <v-row justify="center" no-gutters>
        <v-col
          class="text-center mb-0 pb-0"
          cols="12"
          sm="5"
          style="
            height: 50vh;
            justify-content: center;
            display: flex;
            align-items: center;
          "
        >
          <v-img
            class="d-flex"
            style="align-items: center"
            src="./assets/wasi.jpeg"
            height="100%"
            width="auto"
          >
            <div class="d-flex justify-center">
              <v-img
                max-height="45"
                max-width="45"
                src="./assets/cocoshareIcon.png"
              ></v-img>
              <h1 class="h1 font-weight-medium ml-4 main_text">CoCoShare</h1>
            </div>

            <h3 class="main_text mt-6 text-center">
              今ココをシェアする新しい待ち合わせ
            </h3>

            <div class="caption font-weight-thin mt-1 text-center">
              短時間向け位置情報共有アプリ
            </div>

            <v-btn
              class="black white--text text-left mt-4 px-1"
              style="text-transform: none; background-color: transparent !important;"
              elevation="0"
              onclick="gtag_report_conversion()"
            >
              <v-img
                max-height="100"
                max-width="100"
                src="./assets/app_badge.svg"
              ></v-img>
            </v-btn>
          </v-img>
        </v-col>
        <v-col
          class="d-flex justify-center py-0 my-0"
          cols="12"
          sm="4"
          style="height: 50vh"
        >
          <v-img
            src="./assets/hero@2x-min.png"
            :contain="true"
            height="110%"
            width="auto"
            max-width="100%"
            class="align-self-end"
          ></v-img>
        </v-col>
      </v-row>
      <!--
      <v-row align="center" justify="center" class="white" no-gutters>
        <v-col justify="center" class="text-center">
          <h5 class="h5 mt-2">NEWS</h5>
          <v-simple-table class="mb-2">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>2021-04-10</td>
                  <td>Beta版の公開を開始</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      -->
    </v-main>

    <v-main align="center" justify="center" class="introGray">
      <div class="main_text text-center">
        <h3 class="mt-15">「いまどこいる？」</h3>
        <h3 class="mt-1">「ごめん、あと5分で着く！」</h3>
        <h3 class="mt-8">こんなやりとり、毎回していませんか？</h3>
        <h4 class="mt-8">"今ココ"をシェアして、</h4>
        <h3 class="mt-1 text-center">新しい待ち合わせを体験しよう</h3>
      </div>

      <div class="d-flex justify-center mt-12 mb-5">
        <v-img
          max-height="150"
          max-width="150"
          src="./assets/shareLogation-min.png"
        ></v-img>
      </div>
    </v-main>
    <v-row align="center" justify="center" style="height: 100px" no-gutters>
    </v-row>

    <v-main
      align="center"
      justify="center"
      class="gradationBlue1 main_text text-center"
    >
      <h2 class="mt-6">いつものメンバと</h2>
      <h2>いつでも共有</h2>

      <v-row no-gutters>
        <v-col class="text-center d-flex justify-center pt-0 mt-8">
          <v-img
            max-height="500"
            max-width="300"
            src="./assets/member-min.png"
          ></v-img>
        </v-col>
      </v-row>

      <h4 class="mt-6 mb-6">趣味・仕事仲間、デートの待ち合わせに。</h4>
    </v-main>

    <v-main
      align="center"
      justify="center"
      class="gradationBlue2 main_text text-center"
    >
      <h2 class="mt-6 mb-3">到着予想時間が分かる</h2>
      <v-row no-gutters>
        <v-col class="text-center d-flex justify-center pt-0 mt-8">
          <v-img
            max-height="500"
            max-width="300"
            src="./assets/approaching-min.png"
          ></v-img>
        </v-col>
      </v-row>
      <h4 class="mt-6">いつ頃着くかは一目瞭然</h4>
      <h4 class="mb-6">空いた時間で街ブラしてもいいかも？</h4>
    </v-main>

    <v-main
      align="center"
      justify="center"
      class="gradationBlue3 main_text text-center"
    >
      <h2 class="mt-6">集合場所に近づくと</h2>
      <h2 class="mb-1">通知でお知らせ</h2>
      <v-row no-gutters>
        <v-col class="text-center d-flex justify-center pt-0 mt-8">
          <v-img
            max-height="500"
            max-width="300"
            src="./assets/approaching2-min.png"
          ></v-img>
        </v-col>
      </v-row>
      <h4 class="mt-4 mb-6">スマホをずっと見ていなくても大丈夫。</h4>
    </v-main>

    <v-main
      align="center"
      justify="center"
      class="gradationBlue4 main_text text-center"
    >
      <h2 class="mt-6">１時間で自動的に</h2>
      <h2 class="mb-1">位置情報の共有停止</h2>
      <v-row no-gutters>
        <v-col class="text-center d-flex justify-center pt-0 mt-8">
          <v-img
            max-height="500"
            max-width="300"
            src="./assets/noMember-min.png"
          ></v-img>
        </v-col>
      </v-row>
      <h4 class="mt-4">一時間で自動的に共有オフになるので、</h4>
      <h4 class="mb-6">手軽に利用できます。</h4>
    </v-main>

    <v-main>
      <v-row justify="center" no-gutters>
        <v-col
          class="text-center mb-0 pb-0"
          cols="12"
          style="
            height: 40vh;
            justify-content: center;
            display: flex;
            align-items: center;
          "
        >
          <div>
            <div class="caption font-weight-thin mt-0 mb-1 text-center">
              今ココをシェアする新しい待ち合わせ
            </div>

            <h2 class="h2 font-weight-medium main_text">CoCoShare</h2>

            <div class="d-flex justify-center">
              <v-img
                max-height="120"
                max-width="120"
                src="./assets/cocoshareIcon.png"
              ></v-img>
            </div>

            <v-btn
              class="black white--text text-left mt-4 px-1"
              href="https://apps.apple.com/jp/app/cocoshare-%E4%BD%8D%E7%BD%AE%E5%85%B1%E6%9C%89%E3%82%A2%E3%83%97%E3%83%AA/id1553775974?itsct=apps_box_badge&amp;itscg=30200"
              style="text-transform: none; background-color: transparent !important;"
              elevation="0"
            >
              <v-img
                max-height="100"
                max-width="100"
                src="./assets/app_badge.svg"
              ></v-img>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-main>

    <v-main class="white">
      <div class="caption font-weight-thin mt-5 mb-0 text-center">
        Produced by
      </div>
      <div class="mt-0">
        <v-img
          height="30"
          width="auto"
          max-height="30"
          max-width="100%"
          :contain="true"
          src="./assets/Nibbles_icon2.png"
        ></v-img>
      </div>
      <div class="caption font-weight-thin mt-2 mb-5 text-center">
        <a
          :href="sns.twitter"
          class="twitter-follow-button"
          target="_blank"
        ></a>
      </div>
      <div class="caption font-weight-thin mt-0 mb-0 text-center">
        Copyright © 2020 - 2021 Nibbles All Rights Reserved.
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data: function () {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
      sns: {
        twitter: "https://twitter.com/NibblesJapan",
      },
    };
  },
  mounted() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://platform.twitter.com/widgets.js"
    );
    document.head.appendChild(recaptchaScript);
  },
};
</script>

<style lang="scss" scoped>
.main_text {
  color: #234855;
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap');
#inspire {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 900;
}

</style>