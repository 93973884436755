import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            background: "#EAECD5",
            introGray: "F0F3F5",
            gradationBlue1: "#6CC1D5",
            gradationBlue2: "#56A7B3",
            gradationBlue3: "#4C98A3",
            gradationBlue4: "#42838D",
            descriptionColor: "#234855"
          }
        }
      }
});
